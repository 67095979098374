export const API_PULL_INTERVAL = 60000;

export const SEED_COLLECTOR_APP_STORE_LINK = 'https://apps.apple.com/us/app/terraware/id1568369900';
export const SEED_COLLECTOR_GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.terraformation.seedcollector';

export enum APP_PATHS {
  ACCESSIONS = '/accessions',
  ACCESSIONS2_NEW = '/accessions/new',
  ACCESSIONS2_ITEM = '/accessions/:accessionId',
  ACCESSIONS2_EDIT = '/accessions/:accessionId/edit',
  BATCH_WITHDRAW = '/batch/withdraw',
  CHECKIN = '/checkin',
  CONTACT_US = '/contactus',
  ERROR = '/error',
  ERROR_MOBILE_NOT_SUPPORTED = '/error/mobile-not-supported',
  ERROR_FAILED_TO_FETCH_ORG_DATA = '/error/cannot-fetch-org-data',
  HOME = '/home',
  MONITORING = '/monitoring',
  NURSERIES = '/nurseries',
  NURSERIES_EDIT = '/nurseries/:nurseryId/edit',
  NURSERIES_NEW = '/nurseries/new',
  NURSERIES_VIEW = '/nurseries/:nurseryId',
  INVENTORY = '/inventory',
  INVENTORY_BATCH = '/inventory/batch/:batchId',
  INVENTORY_BATCH_FOR_NURSERY = '/inventory/nursery/:nurseryId/batch/:batchId',
  INVENTORY_BATCH_FOR_SPECIES = '/inventory/species/:speciesId/batch/:batchId',
  INVENTORY_NEW = '/inventory/new',
  INVENTORY_ITEM_FOR_NURSERY = '/inventory/nursery/:nurseryId',
  INVENTORY_ITEM_FOR_SPECIES = '/inventory/:speciesId',
  INVENTORY_WITHDRAW = '/inventory/withdraw',
  OBSERVATIONS = '/observations',
  SCHEDULE_OBSERVATION = '/observations/schedule',
  RESCHEDULE_OBSERVATION = '/observations/schedule/:observationId',
  OBSERVATIONS_SITE = '/observations/:plantingSiteId',
  OBSERVATION_DETAILS = '/observations/:plantingSiteId/results/:observationId',
  OBSERVATION_PLANTING_ZONE_DETAILS = '/observations/:plantingSiteId/results/:observationId/zone/:plantingZoneId',
  OBSERVATION_MONITORING_PLOT_DETAILS = '/observations/:plantingSiteId/results/:observationId/zone/:plantingZoneId/plot/:monitoringPlotId',
  OPT_IN = '/opt-in',
  ORGANIZATION_EDIT = '/organization/edit',
  ORGANIZATION = '/organization',
  PEOPLE_NEW = '/people/new',
  PEOPLE_EDIT = '/people/:personId/edit',
  PEOPLE_VIEW = '/people/:personId',
  PEOPLE = '/people',
  PLANTS_DASHBOARD = '/plants/dashboard',
  PLANTING_SITE_DASHBOARD = '/plants/dashboard/:plantingSiteId',
  PROJECTS_NEW = '/projects/new',
  PROJECT_EDIT = '/projects/:projectId/edit',
  PROJECT_VIEW = '/projects/:projectId',
  PROJECTS = '/projects',
  REPORTS = '/reports',
  REPORTS_EDIT = '/reports/:reportId/edit',
  REPORTS_VIEW = '/reports/:reportId',
  SEED_BANKS = '/seedbanks',
  SEED_BANKS_EDIT = '/seedbanks/:seedBankId/edit',
  SEED_BANKS_NEW = '/seedbanks/new',
  SEED_BANKS_VIEW = '/seedbanks/:seedBankId',
  SEED_BANK_MONITORING = '/monitoring/:seedBankId',
  SEEDS_DASHBOARD = '/seeds-dashboard',
  SPECIES = '/species',
  WELCOME = '/welcome',
  MY_ACCOUNT = '/myaccount',
  MY_ACCOUNT_EDIT = '/myaccount/edit',
  PLANTING_SITES = '/planting-sites',
  PLANTING_SITES_NEW = '/planting-sites/new',
  PLANTING_SITES_SIMPLE_NEW = '/planting-sites/simple/new',
  PLANTING_SITES_DETAILED_NEW = '/planting-sites/detailed/new',
  PLANTING_SITES_VIEW = '/planting-sites/:plantingSiteId',
  PLANTING_SITES_EDIT = '/planting-sites/:plantingSiteId/edit',
  PLANTING_SITES_ZONE_VIEW = '/planting-sites/:plantingSiteId/zone/:zoneId',
  PLANTING_SITES_SUBZONE_VIEW = '/planting-sites/:plantingSiteId/zone/:zoneId/subzone/:subzoneId',
  NURSERY_WITHDRAWALS = '/nursery/withdrawals',
  NURSERY_WITHDRAWALS_DETAILS = '/nursery/withdrawals/:withdrawalId',
  NURSERY_REASSIGNMENT = '/nursery/reassignment/:deliveryId',
}
